import { configureStore } from '@reduxjs/toolkit';
import { studentReducer, parentReducer, schoolRegistrationReducer, studentRegistrationReducer, currentQuestionReducer, consentFormReducer, updateAnswerReducer, formSubmittedReducer, loadingScreenReducer, requiredQuestionsAttemptedReducer, clientConfigurationReducer, formConfigurationReducer } from '../redux/student/studentSlice';

const savedClientConfiguration = JSON.parse( sessionStorage.getItem( 'clientConfiguration' ) ) || {};
const savedFormConfiguration = JSON.parse( sessionStorage.getItem( 'formConfiguration' ) ) || {};

const store = configureStore( {
    reducer: {
        student: studentReducer,
        parent: parentReducer,
        schoolRegistration: schoolRegistrationReducer,
        studentRegistration: studentRegistrationReducer,
        currentQuestion: currentQuestionReducer,
        consentForm: consentFormReducer,
        answersSlice: updateAnswerReducer,
        formSubmitted: formSubmittedReducer,
        loadingScreen: loadingScreenReducer,
        requiredQuestionsAttempted: requiredQuestionsAttemptedReducer,
        clientConfiguration: clientConfigurationReducer,
        formConfiguration: formConfigurationReducer,
    },
    preloadedState: {
        clientConfiguration: savedClientConfiguration,
        formConfiguration: savedFormConfiguration,
    },
} );

export default store;
