import React, { useState, useEffect } from "react";
import { Input, notification } from "antd";
import {
    EditTwoTone,
    CheckCircleOutlined,
}
    from '@ant-design/icons';
import useVerifyOTPEmail from "../../api/VerifyOtpEmail";
import useRequestOTPEmail from "../../api/RequestOtpEmail";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { setIsFormSubmitted } from "../../redux/student/studentSlice";
import useUpdateVerifyStudents from "../../api/useUpdateVerifyStudent";

const EmailVerification = ( {
    onAnswerChange, userEmail, onNext, currentComponent
} ) => {
    const [isEmailValid, setIsEmailValid] = useState( false );
    const [isOTPBoxVisible, setIsOTPBoxVisible] = useState( false );
    const [isEditable, setIsEditable] = useState( false );
    const [email, setEmail] = useState( "" );
    const [otpValues, setOtpValues] = useState( ["", "", "", ""] );
    const [isCheckIcon, setIsCheckIcon] = useState( false );
    const { client_name } = useParams();
    const dispatch = useDispatch();

    const isFormSubmitted = useSelector( state => state.formSubmitted.isFormSubmitted );

    const [isSendOTPClickable, setIsSendOTPClickable] = useState( true );
    const [isVerifyOTPClickable, setIsVerifyOTPClickable] = useState( false );
    const { response: updatedResponse, updateVerifyStudents } = useUpdateVerifyStudents();
    const studentRollNumber = localStorage.getItem( 'student_roll_number' );

    useEffect( () => {
        setEmail( userEmail );
        setIsEmailValid( /^[a-zA-Z0-9]+([\.-]?[a-zA-Z0-9]+){0,5}@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]+$/.test( userEmail ) );
    }, [userEmail] );

    const { requestOTP } = useRequestOTPEmail();
    const { data, isLoading, error, verifyOTP } = useVerifyOTPEmail();

    useEffect( () => {
        if ( !isLoading ) {
            if ( data && !error ) {
                updateVerifyStudents( studentRollNumber, true, false )
            }
        }
    }, [data] );

    useEffect( () => {
        if ( updatedResponse ) {
            notification.success( {
                message: 'OTP Verified',
                description: 'Your OTP has been successfully verified.'
            } );
            currentComponent();
            //dispatch( setIsFormSubmitted( true ) );
            //localStorage.clear();
        }
    }, [updatedResponse] )

    const handleEditEmail = () => {
        setIsEditable( true );
        setIsCheckIcon( true );
        setIsSendOTPClickable( false );
        setIsOTPBoxVisible( false );
        setIsVerifyOTPClickable( false );
    };

    const handleCheckEmail = () => {
        if ( isEmailValid ) {
            setIsEditable( false );
            setIsCheckIcon( false );
            setIsSendOTPClickable( true );
        }
    };

    const handleSendOTP = async () => {
        onNext();
        setIsOTPBoxVisible( true );
        setIsSendOTPClickable( false );
        setIsVerifyOTPClickable( true );
        requestOTP( email, client_name );
    };

    const handleEmailChange = ( value ) => {
        onAnswerChange( value );
        setEmail( value );
        setIsEmailValid( /^[a-zA-Z0-9]+([\.-]?[a-zA-Z0-9]+){0,5}@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]+$/.test( value ) );
    };

    const handleOTPChange = ( e, index ) => {
        const otp = e.target.value;

        if ( otp.length === 0 || /^[0-9]$/.test( otp ) ) {
            const newOtpValues = [...otpValues];
            newOtpValues[index] = otp;
            setOtpValues( newOtpValues );

            if ( otp.length === 0 && index > 0 ) {
                document.getElementById( `otp-input-${ index - 1 }` ).focus();
            } else if ( otp.length === 1 && index < 3 ) {
                document.getElementById( `otp-input-${ index + 1 }` ).focus();
            }
        }
    };

    const handleVerificationSubmit = async () => {
        const otp = otpValues.join( "" );
        // Make an API call to verify OTP
        verifyOTP( email, otp );
    };

    return (
        <>
            <div className={ `${ isFormSubmitted ? 'mt-[30%]' : '' }` }>
                <div className="mt-2">
                    <h1 className="font-poppins text-2xl font-medium bg-white mb-4 select-none">
                        Verify your Email
                    </h1>
                    <>
                        <input
                            disabled={ !isEditable }
                            className={ `w-1/2 rounded ${ isEditable ? " bg-white" : "bg-gray-300" } p-3 border-2 focus:border-blue-500` }
                            style={ { resize: "none" } }
                            value={ email }
                            onChange={ ( e ) => {
                                setEmail( e.target.value );
                                handleEmailChange( e.target.value );
                            } }
                        />
                        {
                            isCheckIcon ? (
                                <CheckCircleOutlined
                                    onClick={ handleCheckEmail }
                                    style={ { fontSize: '25px', cursor: isEmailValid ? 'pointer' : 'not-allowed' } }
                                    className={ `ml-4 ${ isEmailValid ? "#008000" : "#FF0000" }` }
                                />
                            ) : (
                                <EditTwoTone
                                    onClick={ handleEditEmail }
                                    style={ { fontSize: '25px' } }
                                    className="ml-4"
                                    twoToneColor="#2196F3"
                                />
                            )
                        }
                    </>
                </div>
                { isOTPBoxVisible && (
                    <>
                        <div className="flex text-center mt-2">
                            { otpValues.map( ( value, index ) => (
                                <Input
                                    key={ index }
                                    id={ `otp-input-${ index }` }
                                    className="mr-4 w-10 h-15"
                                    value={ value }
                                    maxLength={ 1 }
                                    onChange={ ( e ) => handleOTPChange( e, index ) }
                                />
                            ) ) }
                        </div>
                        <p className="font-poppins text-sm hover:text-red-500 font-medium mt-2">
                            Please enter the OTP sent to your Email
                        </p>
                    </>
                ) }
                <div className="flex flex-row gap-2 justify-end mt-4">
                    <>
                        <button
                            className={ `px-4 py-2 rounded-md text-white ${ isEmailValid && isSendOTPClickable ? "bg-blue-500" : "bg-blue-300" }` }
                            onClick={ isEmailValid && isSendOTPClickable ? handleSendOTP : null }
                            style={ isEmailValid && isSendOTPClickable ? { cursor: 'pointer' } : { cursor: 'not-allowed' } }
                        >
                            Send OTP
                        </button>
                        <button
                            className={ `px-4 py-2 rounded-md text-white ${ isVerifyOTPClickable ? "bg-green-500" : "bg-green-300" }` }
                            onClick={ isVerifyOTPClickable ? handleVerificationSubmit : null }
                            style={ isVerifyOTPClickable ? { cursor: 'pointer' } : { cursor: 'not-allowed' } }
                        >
                            Verify
                        </button>
                    </>
                </div>
            </div>
        </>
    );
};

export default EmailVerification;
