import React, { useState, useEffect } from "react";
import { Image, Spin } from 'antd';
import Advertisement from '../assets/Advertisement.png';
import LoadingAnimation from "../MultiStepForm/ReviewPage/LoadingAnimation";
import { useSelector } from 'react-redux';

const AdvertiseMentModal = ( { onNext, resetAdvertisement, nudge, onBack, onLoad, isPagination, isValidation } ) => {

    const [assessmentCompleted, setAssessmentCompleted] = useState( false );
    const clientConfig = useSelector( state => state.clientConfiguration );

    useEffect( () => {
        if ( nudge?.name === 'ASSESSMENT_QUESTIONS_COMPLETED' ) {
            setAssessmentCompleted( true )
        }
        onLoad();
    }, [nudge] );

    const handleOk = () => {
        resetAdvertisement();
        onNext();
        setAssessmentCompleted( false )
    };

    const handleBack = () => {
        onBack();
    }

    const handleValidation = ( isAnimationCompleted ) => {
        isValidation( isAnimationCompleted );
    }

    const getIdFromDriveLink = ( driveLink ) => {
        if ( driveLink ) {
            const idMatch = driveLink.match( /\/d\/([^/]+)\// );
            if ( idMatch && idMatch[1] ) {
                return idMatch[1];
            }
        }
        return null;
    };

    const imageId = getIdFromDriveLink( nudge?.image_link );

    const imageSrc =
        nudge.image_link === null
            ? Advertisement : imageId
                ? `https://drive.google.com/uc?export=view&id=${ imageId }`
                : nudge?.image_link;

    const isImageLoaded = imageSrc !== '';

    return (
        <>
            <div className="mt-2">
                {
                    assessmentCompleted ?
                        <LoadingAnimation onNext={ handleOk } onBack={ handleBack } isValidation={ ( isValidation ) => handleValidation( isValidation ) } isPagination={ isPagination } />
                        :
                        <div className="text-center">
                            <h1 className="text-4xl font-semibold mb-4">{ nudge.name }</h1>
                            <p className="text-gray-600 text-lg mb-4">{ nudge.text }</p>
                            { isImageLoaded ? (
                                <Image
                                    src={ imageSrc }
                                    alt="Nudge Image"
                                    className="sm:scale-125 lg:scale-100"
                                    preview={ false }
                                />
                            ) : (
                                <Spin size="large" />
                            ) }
                        </div>
                }
            </div>
            {
                assessmentCompleted ?
                    <></> :
                    <div className="flex flex-row gap-2 justify-center mt-4 z-10">
                        <button
                            className="back-button"
                            onClick={ handleBack }
                            disabled={ !isImageLoaded }
                        >
                            Back
                        </button>
                        <button
                            className="next-button"
                            onClick={ handleOk }
                            disabled={ !isImageLoaded }
                            style={ {
                                backgroundColor: clientConfig.primary_color,
                            } }
                        >
                            Next
                        </button>
                    </div>
            }
        </>
    );
};

export default AdvertiseMentModal;
