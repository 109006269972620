import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { notification } from "antd";
import {
  ClockCircleFilled,
} from '@ant-design/icons';

const TestTimer = ( { testTimeInSeconds, isWide, currentComponentNext } ) => {
  const storageId = 'countdown-timer';

  const clientConfig = useSelector( ( state ) => state.clientConfiguration );
  const isConsentForm = useSelector( ( state ) => state.consentForm.isConsentForm );

  const initialStartTime = Date.now();
  const initialEndTime = initialStartTime + testTimeInSeconds * 1000;

  const savedEndTime = localStorage.getItem( storageId );
  const [endTime, setEndTime] = useState( () => ( savedEndTime ? parseInt( savedEndTime, 10 ) : initialEndTime ) );

  const calculateTimeLeft = ( endTime ) => {
    const currentTime = Date.now();
    const timeLeft = Math.max( endTime - currentTime, 0 ) / 1000;
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState( calculateTimeLeft( endTime ) );
  const [notificationShown, setNotificationShown] = useState( false );

  useEffect( () => {
    if ( isConsentForm ) return;

    const interval = setInterval( () => {
      const newTimeLeft = calculateTimeLeft( endTime );
      setTimeLeft( newTimeLeft );

      if ( newTimeLeft === 0 ) {
        clearInterval( interval );
        currentComponentNext();
      }

      if ( newTimeLeft < 600 && !notificationShown ) {
        notification.warning( {
          message: 'Hurry Up!',
          description: 'Your test is about to end!',
          duration: null,
        } );
        setNotificationShown( true );
      }
    }, 1000 );

    return () => clearInterval( interval );
  }, [endTime, notificationShown, isConsentForm] );

  useEffect( () => {
    if ( !isConsentForm ) {
      const newEndTime = initialStartTime + testTimeInSeconds * 1000;
      setEndTime( newEndTime );
      localStorage.setItem( storageId, newEndTime.toString() );
      setTimeLeft( calculateTimeLeft( newEndTime ) );
    }
  }, [isConsentForm, testTimeInSeconds] );

  const timerColor = timeLeft < 600 ? 'text-red-600' : 'text-green-600'; // Less than 10 minutes

  const formatTime = () => {
    const minutes = Math.floor( timeLeft / 60 );
    const seconds = Math.floor( timeLeft % 60 );
    return `${ minutes }:${ seconds < 10 ? '0' : '' }${ seconds }`;
  };

  const displayTime = () => {
    if ( isConsentForm ) {
      const minutes = Math.floor( testTimeInSeconds / 60 );
      return `${ minutes }:00`;
    }
    return formatTime();
  };

  return (
    <div className="h-fit flex flex-row w-full items-center justify-center font-poppins">
      { !isWide && <ClockCircleFilled style={ { fontSize: '2rem', color: clientConfig.primary_color } } /> }
      <p className={ `${ isWide ? 'text-2xl w-full' : 'text-2xl ml-[1.5rem] w-fit' } text-black font-semibold` }>
        Time Left :
        <span className={ `${ isWide ? 'text-2xl' : 'text-2xl' } ${ timerColor }` }>
          { displayTime() }
        </span>
      </p>
    </div>
  );
};

export default TestTimer;