import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics";
import { dropdownCategory, dropdownAction } from "../../utils/GoogleAnalytics/events/categories";
import "../../styles.css";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { useParams } from 'react-router-dom';
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";
import { useSelector } from 'react-redux';
import useFetchSkills from "../../api/FetchSkills";

const { Option } = Select;

const SkillSelector = ({ question, selectedOptionsFromParent, onSelect, onNext, onBack, isPagination, isQuestionNavigation, isValidation }) => {
  const [selected, setSelected] = useState([]);
  const [ratings, setRatings] = useState({});
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [experience, setExperience] = useState();
  const [skillType, setSkillType] = useState();

  const numericalDigits = question?.description.match(/\d+/g)?.map(Number)[0];
  const isDescriptionIncludesDigit = /\d/.test(question?.description);

  const clientConfig = useSelector(state => state.clientConfiguration);
  const { response: skills, isLoading, FetchSkills } = useFetchSkills();

  const checkIfAllRated = (ratingsObj, selectedOptions) => {
    return selectedOptions.every(option => ratingsObj[option] && ratingsObj[option] > 0);
  };

  useEffect(() => {
    setSelected(selectedOptionsFromParent ? Object.keys(selectedOptionsFromParent) : []);
    setRatings(selectedOptionsFromParent || {});
    if (selectedOptionsFromParent && Object.keys(selectedOptionsFromParent).length === numericalDigits) {
      setIsOptionSelected(checkIfAllRated(selectedOptionsFromParent, Object.keys(selectedOptionsFromParent)));
      isValidation(checkIfAllRated(selectedOptionsFromParent, Object.keys(selectedOptionsFromParent)));
    } else {
      setIsOptionSelected(false);
      isValidation(false);
    }
  }, [selectedOptionsFromParent]);

  useEffect(() => {
    const exp = localStorage.getItem('experience');
    if (exp === "Less than 1 year") {
      setExperience(0);
    } else if (exp === "1 - 3 years") {
      setExperience(1);
    } else if (exp === "More than 3 years") {
      setExperience(2);
    }
  }, []);

  useEffect(() => {
    if (question?.description.toLowerCase().includes('hard skills')) {
      setSkillType('hard_skills');
    } else if (question?.description.toLowerCase().includes('soft skills')) {
      setSkillType('soft_skills');
    }
  }, [question?.description]);

  useEffect(() => {
    if (skillType) {
      FetchSkills(skillType, experience);
    }
  }, [skillType, experience]);

  const handleSelect = (selectedOptions) => {
    if (isDescriptionIncludesDigit && selectedOptions.length > numericalDigits) {
      return;
    }

    const newRatings = {};
    selectedOptions.forEach(skill => {
      if (ratings[skill]) {
        newRatings[skill] = ratings[skill];
      } else {
        newRatings[skill] = 0; // Default rating when newly selected
      }
    });
    setRatings(newRatings);
    onSelect(newRatings);
    setSelected(selectedOptions);
    setIsOptionSelected(checkIfAllRated(newRatings, selectedOptions) && selectedOptions.length === numericalDigits);
    isValidation(checkIfAllRated(newRatings, selectedOptions) && selectedOptions.length === numericalDigits)
  };

  const handleRating = (skill, rating) => {
    const newRatings = { ...ratings, [skill]: rating };
    setRatings(newRatings);
    onSelect(newRatings);
    setIsOptionSelected(checkIfAllRated(newRatings, selected) && selected.length === numericalDigits);
    isValidation(checkIfAllRated(newRatings, selected) && selected.length === numericalDigits);
  };

  const { client_name } = useParams();
  const perQuestionTimer = dynamicData.default.perQuestionTimer;

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && isOptionSelected) {
      onNext();
    }
  };

  return (
    <div className={`flex flex-col justify-between ${isPagination ? "mb-4" : "mt-2 lg:h-full"}`}>
      <div className="flex flex-col h-full">
        <QuestionAndDescription question={question} />
        <div className="flex flex-row items-center gap-2 mb-4">
          <div className="font-semibold">Eg: Data Analytics:</div>
          <div className="flex gap-2">
            {[1, 2, 3, 4, 5].map(rating => (
              <div key={rating} className={`w-8 h-8 rounded-full border flex items-center justify-center ${rating === 3 ? 'bg-green-500 text-white' : 'bg-gray-100 text-black'}`}>
                {rating}
              </div>
            ))}
          </div>
        </div>
        <Select
          className="w-full dynamic-dropdown"
          value={selected}
          onChange={handleSelect}
          mode="multiple"
          showSearch={true}
          placeholder={question?.placeholder_text}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onKeyDown={handleKeyDown}
          tabIndex={0}
        >
          {skills?.map((skill, index) => (
            <Option key={index} value={skill}>
              {skill}
            </Option>
          ))}
        </Select>
        <div className="mt-4 overflow-y-auto">
          {selected?.map(skill => (
            <div key={skill} className="lg:w-3/4 sm:w-full flex flex-row items-center justify-between gap-2 mb-4">
              <div className="font-semibold">{skill}:</div>
              <div className="flex gap-2">
                {[1, 2, 3, 4, 5].map(rating => (
                  <button
                    key={rating}
                    className={`w-8 h-8 rounded-full border flex items-center justify-center ${ratings[skill] === rating ? 'bg-green-500 text-white' : 'bg-white text-black'}`}
                    onClick={() => handleRating(skill, rating)}
                  >
                    {rating}
                  </button>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      {isPagination ? (
        <></>
      ) : isQuestionNavigation ? (
        <>
          <SubmitButton onNext={onNext} />
        </>
      ) : (
        <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
          {question?.question_optional === "optional" && (
            <button className="skip-button" onClick={onNext}>
              Skip
            </button>
          )}
          {perQuestionTimer ? (
            <></>
          ) : (
            <button className="back-button" onClick={onBack}>
              Back
            </button>
          )}
          <button
            className="next-button"
            onClick={isOptionSelected ? onNext : null}
            style={{
              backgroundColor: isOptionSelected ? `${clientConfig.primary_color}` : `${clientConfig.primary_color}80`,
              cursor: isOptionSelected ? 'pointer' : 'not-allowed'
            }}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default SkillSelector;
