import React, { useState, useEffect } from 'react';
import EnableTick from '../../assets/enable_tick.svg';
import DisableTick from '../../assets/disable_tick.svg';
import LoaderAnimation from '../../assets/Loader Animation.gif';
import { useSelector } from 'react-redux';
import { dynamicData } from "../../helpers/data";

const LoadingAnimation = ( { onNext, onBack, isValidation, isPagination } ) => {

    const handleOk = () => {
        onNext();
    };

    const handleBack = () => {
        onBack();
    }

    const formConfigRedux = useSelector( state => state.formConfiguration );
    const formConfig = formConfigRedux?.form_config;
    const clientid = formConfig?.client_id

    let heading =
        formConfig?.loading_page?.heading ||
        dynamicData[clientid]?.loading_page?.heading ||
        dynamicData.default?.loading_page?.heading

    let pointers =
        formConfig?.loading_page?.pointers ||
        dynamicData[clientid]?.loading_page?.pointers ||
        dynamicData.default?.loading_page?.pointers

    const [showReportButton, setShowReportButton] = useState( false );

    const initialEnableIcons = Array( pointers.length ).fill( false );

    const [enableIcons, setEnableIcons] = useState( initialEnableIcons );


    useEffect( () => {
        // Create timers based on the length of pointers
        const timers = pointers.map( ( _, index ) => {
            return setTimeout( () => {
                setEnableIcons( prevState => {
                    const newState = [...prevState];
                    newState[index] = true;
                    return newState;
                } );
            }, ( index + 1 ) * 1000 ); // Increase the time for each pointer
        } );

        // Show report button after all pointers are processed
        const reportButtonTimer = setTimeout( () => {
            setShowReportButton( true );
            isValidation( true )
        }, ( pointers.length + 1 ) * 1000 );

        timers.push( reportButtonTimer );

        return () => {
            timers.forEach( ( timer ) => clearTimeout( timer ) );
        };
    }, [pointers] );

    const pointersList = pointers.map( ( pointer, index ) => (
        <div key={ index } className="flex item-start">
            <img src={ enableIcons[index] ? EnableTick : DisableTick } className="w-6 h-6" alt="Tick Icon" />
            <p className={ `pl-2 text-base font-normal mb-3 ${ enableIcons[index] ? 'text-black' : 'text-[#706E84]' } sm:text-sm md:text-sm` }>{ pointer }</p>
        </div>
    ) );

    return (
        <>
            <div className="font-poppins w-full flex flex-col items-center justify-center">
                <div className="text-center">
                    {
                        showReportButton ?
                            <h1 className="mb-4 text-lg font-semibold text-[#352958] sm:text-xl md:text-2xl">Our AI has calculated your results...</h1>
                            :
                            <></>
                    }

                </div>
                <div className="text-center mt-2 mb-5">
                    {
                        showReportButton && !isPagination ?
                            <>
                                <div className='flex flex-row gap-5'>
                                    <button
                                        class="inline-block rounded border border-current px-8 py-3 text-sm font-medium text-indigo-600 transition hover:scale-110 hover:shadow-xl focus:outline-none focus:ring active:text-indigo-500"
                                        onClick={ handleBack }
                                    >
                                        Modify Answers
                                    </button>

                                    <button
                                        class="inline-block rounded bg-blue-500 px-8 py-3 text-sm font-medium text-white transition hover:scale-110 hover:shadow-xl focus:outline-none focus:ring active:bg-indigo-500"
                                        onClick={ handleOk }
                                    >
                                        Go to Reports
                                    </button>
                                </div>
                            </>
                            :
                            <img src={ LoaderAnimation } className="mx-auto w-[22.5rem]" alt="SheeHulk" />

                    }
                </div>
                <div className="text-center">
                    {
                        showReportButton ?
                            <></> :
                            <>
                                <h1 className="mb-4 text-lg font-semibold text-[#352958] sm:text-xl md:text-2xl">{ heading }</h1>
                                <div className='text-left'>
                                    { pointersList }
                                </div>
                            </>
                    }

                </div>
            </div>
        </>
    );
};

export default LoadingAnimation;
