import React from 'react';
import { useSelector } from 'react-redux';
import {
    ClockCircleFilled,
} from '@ant-design/icons';

const StillTimer = ( { testTimeInSeconds, isWide } ) => {
    const clientConfig = useSelector( ( state ) => state.clientConfiguration );

    const mainTimer = () => {
        const minutes = Math.floor( testTimeInSeconds / 60 );
        const seconds = Math.floor( testTimeInSeconds % 60 );
        return `${ minutes }:${ seconds }`;
    }

    return (
        <div className="h-fit flex flex-row w-full items-center justify-center">
            {
                !isWide && <ClockCircleFilled style={ { fontSize: '2rem', color: clientConfig.primary_color } } />

            }
            <p className={ `${ isWide ? 'text-2xl' : 'text-xl ml-[1.5rem]' } text-black font-semibold w-full` }>Time Left : <span className={ `${ isWide ? 'text-2xl' : 'text-xl' }` }>{ mainTimer() }</span></p>
        </div>

    );
};

export default StillTimer;