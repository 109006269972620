import React from 'react'
import TestComleted from '../../assets/all done.svg';

const TestSubmissionPage = () => {
    const isTest = window.location.href.includes( 'test' )

    return (
        <>
            <div className={ isTest ? 'flex justify-center items-center h-full' : 'mt-2 w-full' }>
                <img src={ TestComleted } preview={ false } className={ isTest ? 'w-1/2' : 'w-full' } />
            </div>
        </>
    )
}

export default TestSubmissionPage