import React, { useState, useEffect } from 'react';
import ThankYouPageOne from './ThankYouPageOne';
import useGeneratePdfReport from '../../api/useGeneratePdfReport';
import { notification, Spin } from 'antd';
import { useSelector } from 'react-redux';

export const ThankYouJaroEdu = () => {
    const [studentRollNumber, setStudentRollNumber] = useState( null );
    const formConfigRedux = useSelector( state => state.formConfiguration );
    const student_name = localStorage.getItem( 'studentName' ) || "abc";
    const student_email = localStorage.getItem( 'user_email' ) || "abc@gmail.com";

    const formConfig = formConfigRedux?.form_config;
    const formid = formConfig?.form_id;
    const bookingLink = formConfig?.booking_url;

    const userID = localStorage.getItem( 'userAssessmentResponseID' );
    const { data: pdfReport, isLoading: pdfReportGenerating, generatePdfReport } = useGeneratePdfReport();

    const handleBooking = () => {
        if (pdfReportGenerating) {
            notification.info({
                message: 'PDF Report is on its way',
            });
        }
        if (pdfReport) {
            try {
                const url = new URL(pdfReport);
                window.open(url, '_blank');
            } catch (e) {
                console.error('Invalid PDF report URL:', pdfReport);
            }
        }
    };    

    const handlementorshipbooking = () => {
        window.open( `${ bookingLink }/?name=${ student_name }&email=${ student_email }`, '_blank' );
    };
    
    useEffect( () => {
        setStudentRollNumber( localStorage.getItem( 'student_roll_number' ) );
    }, [] )

    useEffect( () => {
        if ( studentRollNumber ) {
            generatePdfReport( studentRollNumber, formid )
        }
    }, [studentRollNumber] )

    return (
        <>
            <ThankYouPageOne
                handleReport={ handleBooking }
                handlementorshipbooking={ handlementorshipbooking }
            />
        </>
    );
};